import React from 'react'
import clsx from 'clsx'
import { H1 } from '@sakura-ui/core'
import {
  BreadcrumbsItem,
  Breadcrumbs,
  LangSelector,
  MenuButton
} from '../molecules'
import { FooterLogo } from '../organisms'
import { UserInsight } from '../atoms'
import { usePageContext } from '../context/PageContext'
import { useTranslation } from 'react-i18next'
import { localizedPath, type Menu } from 'helper'
import { useSimpleAuthenticator } from '../hooks'
import { styleClickable, styleFocus, styleHoverUnderline } from '../styles'

export interface ServiceLayoutProps {
  title: string
  serviceId: string
  serviceName: string
  items?: BreadcrumbsItem[]
  menuList?: Menu[]
  children: React.ReactNode
}

export const ServiceLayout = ({
  title,
  serviceId,
  serviceName,
  items,
  menuList = [],
  children
}: ServiceLayoutProps) => {
  const { t } = useTranslation()
  const { lang, langs, originalPath } = usePageContext()

  const authenticated = useSimpleAuthenticator()
  if (!authenticated) {
    return <></>
  }

  const style = `
    min-h-screen
    text-sumi-900
    text-base
    font-medium
    leading-8
  `

  const containerStyle = `
    max-w-[336px]
    sm:max-w-screen-sm
    md:max-w-screen-md
    lg:max-w-screen-lg
    xl:max-w-[1120px]
    mx-auto
  `

  const breadcrumbsStyle = `
    my-12
    rounded-3xl
    py-2
    px-4
    bg-sumi-100
  `

  const menuItem = `
    block
    py-1
    ${styleClickable}
    ${styleHoverUnderline}
    ${styleFocus}
  `

  const menuSelected = `
    rounded-full
    bg-wood-50
  `

  const buildNavi = () => {
    const path = localizedPath(originalPath, lang)

    const langMap: { [key: string]: string } = {
      en: 'English',
      ja: '日本語',
      ko: '한국어',
      'zh-cmn-hans': '简体中文',
      'zh-cmn-hant': '繁體中文'
    }

    const dispLangs = langs.sort().map((l) => {
      return {
        code: l,
        title: langMap[l],
        path: localizedPath(originalPath, l)
      }
    })

    return (
      <nav>
        <ul className="flex gap-4">
          <li>
            <div>
              <ul className="hidden sm:flex sm:gap-4 sm:items-center">
                {menuList.map((menu) => (
                  <li>
                    <a
                      className={clsx(
                        menuItem,
                        menu.url === path ? menuSelected : ''
                      )}
                      href={menu.url}
                    >
                      {/*href={menu.url}*/}
                      {menu.title}
                    </a>
                  </li>
                ))}
                {langs.length > 1 && menuList.length > 0 ? (
                  <li className="text-sumi-600">|</li>
                ) : (
                  ''
                )}
              </ul>
            </div>
          </li>
          {langs.length > 1 ? (
            <li className="">
              <LangSelector current={lang} langs={dispLangs} />
            </li>
          ) : (
            ''
          )}
          {menuList.length > 0 ? (
            <li className="sm:hidden">
              <MenuButton currentUrl={path} menuList={menuList} />
            </li>
          ) : (
            ''
          )}
        </ul>
      </nav>
    )
  }

  return (
    <div className={style}>
      <header
        className={clsx('max-w-[1120px] mx-auto sticky top-0 bg-white/[0.88]')}
      >
        <div className="p-6 xl:px-0 flex justify-between items-center">
          <a className="" href={localizedPath(`/${serviceId}`, lang)}>
            <p className="!font-bold text-lgm sm:text-lg">{serviceName}</p>
          </a>
          {buildNavi()}
        </div>
      </header>
      <main className={containerStyle}>
        <article>
          {title !== '' ? <H1>{title}</H1> : ''}
          {children}
          {items ? (
            <Breadcrumbs
              className={breadcrumbsStyle}
              items={items.map((item) => {
                if (item.href) {
                  item.href = localizedPath(item.href, lang)
                }
                return item
              })}
            />
          ) : (
            ''
          )}
        </article>
      </main>
      <footer className="mt-24 text-base-sm">
        <p className="mb-8 text-center text-base !font-bold">
          <a href="/">{t('global.title')}</a>
        </p>
        <FooterLogo />
      </footer>
      <UserInsight />
    </div>
  )
}
