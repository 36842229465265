import clsx from 'clsx'
import React from 'react'
import { LinkCard, LinkCardHeader, CardBody } from '@sakura-ui/core'

export interface LinkCardProps extends React.ComponentProps<'article'> {
  href: string
}

export interface ServiceCardProps extends React.ComponentProps<'article'> {
  title: string
  url: string
  body: string
}

export const ServiceCard = ({
  className,
  url,
  title,
  body
}: ServiceCardProps) => {
  const styleHeading = `
    flex
    justify-between
    items-center
  `

  return (
    <LinkCard href={url} className={clsx(className)}>
      <LinkCardHeader className={styleHeading}>{title}</LinkCardHeader>
      <CardBody>{body}</CardBody>
    </LinkCard>
  )
}
