import React from 'react'
import clsx from 'clsx'
import { type Menu as MenuType } from 'helper'
import { styleClickable, styleFocus, styleHoverUnderline } from '../styles'

export interface MenuButtonProps extends React.ComponentPropsWithRef<'button'> {
  currentUrl: string
  menuList: MenuType[]
}

export const MenuButton = ({
  currentUrl,
  menuList,
  className,
  ...rest
}: MenuButtonProps) => {
  const styleButton = `
    touch-manipulation
    ${styleClickable}
    ${styleHoverUnderline}
    ${styleFocus}
  `

  const menuItem = `
    block
    py-1
    ${styleClickable}
    ${styleHoverUnderline}
    ${styleFocus}
  `

  const menuSelected = `
    rounded-full
    bg-wood-50
  `

  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const dialogRef = React.useRef<HTMLDialogElement>(null)
  const menuListRef = React.useRef<HTMLAnchorElement[]>([])

  const open = () => {
    dialogRef.current?.show()
    document.body.style.overflow = 'hidden'
  }
  const close = () => {
    dialogRef.current?.close()
    document.body.style.overflow = 'auto'
  }

  const keyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (e.key) {
      case 'Tab':
        if (e.shiftKey) {
          break
        }
        e.preventDefault()
        menuListRef.current[0].focus()
        break
    }
  }

  const keyDownMenuItem = (
    e: React.KeyboardEvent<HTMLButtonElement>,
    index: number
  ) => {
    if (index !== 0) {
      return
    }
    switch (e.key) {
      case 'Tab':
        if (e.shiftKey) {
          e.preventDefault()
          buttonRef.current?.focus()
        }
        break
    }
  }

  return (
    <div className={className} {...rest}>
      <dialog ref={dialogRef} className="peer" autoFocus>
        <div
          className="z-10 fixed top-0 left-0 right-0 bottom-20"
          onClick={close}
        ></div>
        <div
          className="z-10 fixed top-20 left-0 right-0 bottom-0"
          onClick={close}
        >
          <div className="h-full bg-sumi-500 opacity-50" />
        </div>
        <div className="z-20 fixed top-20 left-0 right-0">
          <nav className="py-8 px-6 xl:px-0 bg-white">
            <ul className="flex flex-col gap-4">
              {menuList.map((menu, index) => (
                <li key={menu.url}>
                  <a
                    ref={(el: HTMLAnchorElement | null) => {
                      if (el !== null) {
                        menuListRef.current[index] = el
                      }
                    }}
                    className={clsx(
                      `${index}`,
                      menuItem,
                      menu.url === currentUrl ? menuSelected : ''
                    )}
                    href={menu.url}
                    onKeyDown={(e) => keyDownMenuItem(e, index)}
                  >
                    {menu.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </dialog>
      <button
        type="button"
        className={clsx(styleButton, 'inline peer-open:hidden')}
        onClick={open}
      >
        <IconMenu />
      </button>
      <button
        ref={buttonRef}
        type="button"
        className={clsx(styleButton, 'z-20 relative peer-open:inline hidden')}
        onClick={close}
        onKeyDown={keyDown}
      >
        <IconClose />
      </button>
    </div>
  )
}

const IconMenu = ({ className, ...rest }: React.ComponentProps<'svg'>) => {
  return (
    <svg
      aria-label={`${rest['aria-label'] ?? 'MENU'}`}
      className={className}
      fill="none"
      height="44"
      role="img"
      viewBox="0 0 44 44"
      width="44"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 7H5V9H39V7ZM39 15H5V17H39V15ZM5 23H39V25H5V23Z"
        fill="currentColor"
      />
      <path
        d="M35.6474 38C33.6914 38 32.3594 37.0507 32.3594 34.4907V30H33.7514V34.576C33.7514 36.3467 34.5434 36.9227 35.6474 36.9227C36.7634 36.9227 37.5794 36.3467 37.5794 34.576V30H38.9234V34.4907C38.9234 37.0507 37.6034 38 35.6474 38Z"
        fill="currentColor"
      />
      <path
        d="M23.1172 37.8613V30H24.5452L27.4732 34.6187L28.3972 36.2827H28.4572C28.3852 35.472 28.2772 34.5227 28.2772 33.6693V30H29.5972V37.8613H28.1692L25.2412 33.232L24.3172 31.5893H24.2572C24.3292 32.4 24.4372 33.3067 24.4372 34.16V37.8613H23.1172Z"
        fill="currentColor"
      />
      <path
        d="M15.4414 37.8613V30H20.6614V31.0453H16.8334V33.232H20.0734V34.2773H16.8334V36.816H20.7934V37.8613H15.4414Z"
        fill="currentColor"
      />
      <path
        d="M5 37.8613V30H6.596L8.216 33.9787C8.42 34.5013 8.6 35.0347 8.804 35.5573H8.864C9.068 35.0347 9.236 34.5013 9.44 33.9787L11.036 30H12.644V37.8613H11.348V33.9787C11.348 33.2747 11.456 32.2613 11.528 31.5467H11.48L10.772 33.36L9.236 37.104H8.372L6.824 33.36L6.128 31.5467H6.08C6.14 32.2613 6.248 33.2747 6.248 33.9787V37.8613H5Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconClose = ({ className, ...rest }: React.ComponentProps<'svg'>) => {
  return (
    <svg
      aria-label={`${rest['aria-label'] ?? 'CLOSE'}`}
      className={className}
      fill="none"
      height="44"
      role="img"
      viewBox="0 0 44 44"
      width="44"
      {...rest}
    >
      <path
        d="M37.0023 38.8911H32.8164V31.1197H37.0023V32.121H33.941V34.3415H36.8149V35.3319H33.941V37.8789H37.0023V38.8911Z"
        fill="currentColor"
      />
      <path
        d="M31.4644 36.7904C31.4644 37.2548 31.3568 37.6503 31.1416 37.9768C30.9264 38.3034 30.6175 38.5573 30.2148 38.7387C29.8122 38.9129 29.3332 39 28.7779 39C28.5071 39 28.2468 38.9818 27.9969 38.9455C27.747 38.9165 27.5145 38.873 27.2993 38.8149C27.0841 38.7569 26.8932 38.6843 26.7266 38.5972V37.487C27.0042 37.6177 27.3305 37.7374 27.7054 37.8462C28.0802 37.9478 28.462 37.9986 28.8508 37.9986C29.184 37.9986 29.4616 37.9514 29.6838 37.8571C29.9059 37.7628 30.0725 37.6322 30.1836 37.4653C30.2947 37.2984 30.3502 37.1025 30.3502 36.8775C30.3502 36.6381 30.2912 36.4385 30.1732 36.2789C30.0621 36.112 29.8851 35.9596 29.6421 35.8217C29.4061 35.6766 29.0868 35.5242 28.6842 35.3646C28.4065 35.2558 28.1531 35.136 27.924 35.0054C27.7019 34.8675 27.504 34.7079 27.3305 34.5265C27.1639 34.3451 27.0355 34.1347 26.9452 33.8952C26.855 33.6558 26.8099 33.3764 26.8099 33.0571C26.8099 32.629 26.9105 32.2626 27.1118 31.9578C27.3201 31.653 27.6047 31.4208 27.9657 31.2612C28.3267 31.0943 28.7466 31.0109 29.2256 31.0109C29.6282 31.0109 29.9996 31.0544 30.3398 31.1415C30.6869 31.2213 31.0166 31.3338 31.329 31.4789L30.975 32.4476C30.6903 32.3242 30.4023 32.2227 30.1107 32.1428C29.8191 32.063 29.5137 32.0231 29.1944 32.0231C28.9167 32.0231 28.6842 32.0666 28.4967 32.1537C28.3093 32.2408 28.167 32.3605 28.0698 32.5129C27.9796 32.6653 27.9344 32.8431 27.9344 33.0462C27.9344 33.2857 27.9865 33.4852 28.0906 33.6449C28.1948 33.8045 28.3614 33.9533 28.5904 34.0911C28.8195 34.2217 29.1215 34.3669 29.4964 34.5265C29.9129 34.6934 30.2669 34.8748 30.5585 35.0707C30.857 35.2666 31.0826 35.5025 31.2353 35.7782C31.388 36.0467 31.4644 36.3841 31.4644 36.7904Z"
        fill="currentColor"
      />
      <path
        d="M25.6862 34.9946C25.6862 35.5968 25.6133 36.1447 25.4675 36.6381C25.3287 37.1243 25.1135 37.5451 24.8219 37.9007C24.5373 38.2562 24.1763 38.5283 23.739 38.717C23.3086 38.9057 22.8053 39 22.2292 39C21.6391 39 21.1254 38.9057 20.6881 38.717C20.2577 38.5211 19.8967 38.249 19.6052 37.9007C19.3205 37.5451 19.1088 37.1206 18.97 36.6272C18.8311 36.1338 18.7617 35.5859 18.7617 34.9837C18.7617 34.1855 18.8867 33.4889 19.1366 32.8939C19.3865 32.2989 19.7683 31.8345 20.282 31.5007C20.8026 31.1669 21.4552 31 22.2396 31C23.0032 31 23.6384 31.1669 24.1451 31.5007C24.6588 31.8272 25.0441 32.2916 25.3009 32.8939C25.5578 33.4889 25.6862 34.1891 25.6862 34.9946ZM19.9488 34.9946C19.9488 35.6186 20.0286 36.1555 20.1883 36.6054C20.3479 37.0481 20.5978 37.3927 20.938 37.6394C21.2781 37.8789 21.7085 37.9986 22.2292 37.9986C22.7567 37.9986 23.1871 37.8789 23.5203 37.6394C23.8536 37.3927 24.1 37.0481 24.2596 36.6054C24.4193 36.1555 24.4991 35.6186 24.4991 34.9946C24.4991 34.0585 24.3187 33.3292 23.9577 32.8068C23.6036 32.2771 23.0309 32.0122 22.2396 32.0122C21.712 32.0122 21.2781 32.132 20.938 32.3714C20.5978 32.6109 20.3479 32.9519 20.1883 33.3946C20.0286 33.8372 19.9488 34.3705 19.9488 34.9946Z"
        fill="currentColor"
      />
      <path
        d="M13.8516 38.8911V31.1197H14.9761V37.8789H18.152V38.8911H13.8516Z"
        fill="currentColor"
      />
      <path
        d="M10.5403 32.0231C10.1724 32.0231 9.84268 32.092 9.55112 32.2299C9.25957 32.3605 9.00966 32.5564 8.80141 32.8177C8.60009 33.0789 8.4439 33.3945 8.33283 33.7646C8.2287 34.1347 8.17664 34.5519 8.17664 35.0163C8.17664 35.6258 8.26341 36.1555 8.43696 36.6054C8.61051 37.048 8.87082 37.3891 9.21792 37.6285C9.56501 37.868 10.0023 37.9877 10.5299 37.9877C10.8492 37.9877 11.1547 37.9587 11.4462 37.9007C11.7447 37.8353 12.0467 37.7519 12.3522 37.6503V38.6626C12.0606 38.7787 11.7621 38.8621 11.4567 38.9129C11.1512 38.9709 10.7972 39 10.3946 39C9.6379 39 9.00619 38.8367 8.49944 38.5102C7.99962 38.1836 7.62477 37.7192 7.37486 37.117C7.12495 36.5147 7 35.8109 7 35.0054C7 34.4177 7.07636 33.8807 7.22908 33.3945C7.38874 32.9011 7.61782 32.4766 7.91632 32.1211C8.21482 31.7655 8.58274 31.4934 9.02007 31.3047C9.46435 31.1088 9.97458 31.0109 10.5507 31.0109C10.9256 31.0109 11.2935 31.0544 11.6545 31.1415C12.0155 31.2213 12.3417 31.3374 12.6333 31.4898L12.2168 32.4694C11.9738 32.3533 11.71 32.2517 11.4254 32.1646C11.1477 32.0703 10.8527 32.0231 10.5403 32.0231Z"
        fill="currentColor"
      />
      <path
        d="M12.8947 26L11 24.1053L20.1053 15L11 5.89474L12.8947 4L22 13.1053L31.1053 4L33 5.89474L23.8947 15L33 24.1053L31.1053 26L22 16.8947L12.8947 26Z"
        fill="currentColor"
      />
    </svg>
  )
}
