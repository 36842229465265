export const styleClickable = `
  px-2
  min-h-[calc(44/16*1rem)]
  border
  border-transparent
  rounded-lg
  hover:bg-solid-grey-50
`

export const styleFocus = `
  focus-visible:outline
  focus-visible:outline-2
  focus-visible:outline-offset-[calc(2/16*1rem)]
  focus-visible:outline-wood-600
`

export const styleHoverUnderline = `
  hover:underline
  hover:underline-offset-[calc(3/16*1rem)]
`
